import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { getDashboardDetails } from '../../api';
import { showSwal } from '../../constants/Common';
import { toAbsoluteUrl } from '../../_patidarsagai/_helpers';

export function DashboardPage() {
  const [dashboardData, setDashboardData] = React.useState({
    approvalStatus: 0,
    pendingPhoto: 0,
    memberReportOfWeek: 0,
    profileUpdateRequest: 0,
    newUser: 0,
    newMember: 0,
    yesterdayUser: 0,
    yesterdayMember: 0,
    lastWeekUser: 0,
    lastWeekMember: 0,
    lastMonthUser: 0,
    lastMonthMember: 0,
    activeLoginMember: 0,
    openTicketCount: 0,
  });

  useEffect(() => {
    getDashboardDetails()
      .then((resp) => {
        setDashboardData({
          approvalStatus: resp.approvalStatus,
          pendingPhoto: resp.pendingPhoto,
          memberReportOfWeek: resp.memberReportOfWeek,
          profileUpdateRequest: resp.profileUpdateRequest,
          newUser: resp.newUser,
          newMember: resp.newMember,
          yesterdayUser: resp.yesterdayUser,
          yesterdayMember: resp.yesterdayMember,
          lastWeekUser: resp.lastWeekUser,
          lastWeekMember: resp.lastWeekMember,
          lastMonthUser: resp.lastMonthUser,
          lastMonthMember: resp.lastMonthMember,
          activeLoginMember: resp.activeLoginMember,
          openTicketCount: resp.openTicketCount,
        });
      })
      .catch((error) => showSwal(error));
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-3">
          <a href="/member/member-list">
            <div className="card bg-info card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.approvalStatus}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Pending Approval - Member List
                </span>
              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-3">
          <a href="/member/images-request">
            <div className="card bg-danger card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Picture.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.pendingPhoto}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Pending - Member Photos
                </span>
              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-3">
          <a href="/member/report">
            <div className="card bg-warning card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.memberReportOfWeek}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Member Report of the Week
                </span>
              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-3">
          <a href="member/member-request">
            <div className="card bg-primary card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Pencil.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.profileUpdateRequest}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Pending - Member Request
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3">
          <a href="/administrator/user-list">
            <div className="card bg-success card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.newMember}/{dashboardData.newUser}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Today's New User
                </span>
              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-3">
          <a href="/administrator/user-list">
            <div className="card bg-success card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.yesterdayMember}/{dashboardData.yesterdayUser}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Yesterday User
                </span>
              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-3">
          <a href="/administrator/user-list">
            <div className="card bg-success card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.lastWeekMember}/{dashboardData.lastWeekUser}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Last Week User
                </span>
              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-3">
          <a href="/administrator/user-list">
            <div className="card bg-success card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.lastMonthMember}/{dashboardData.lastMonthUser}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Last Month User
                </span>
              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-3">
          <a href="/administrator/login-history">
            <div className="card bg-dark card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.activeLoginMember}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">
                  Active Login User
                </span>
              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-3">
          <a href="administrator/ticket">
            <div className="card bg-danger card-custom gutter-b">
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-light">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Ticket.svg')} />
                </span>
                <div className="text-light font-weight-bolder font-size-h2 mt-3">
                  {dashboardData.openTicketCount}
                </div>
                <span className="text-light font-weight-bolder font-size-lg mt-1">Open Ticket</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}
