import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.fromMemberName
    ? `(fromMemberName like '%${queryParams.filter.fromMemberName}%' OR `
    : ``;
  searchText += queryParams.filter.toMemberName
    ? `toMemberName like '%${queryParams.filter.toMemberName}%' OR `
    : ``;
  searchText += queryParams.filter.fromProfileId
    ? `fromProfileId like '%${queryParams.filter.fromProfileId}%' OR `
    : ``;
  searchText += queryParams.filter.toProfileId
    ? `toProfileId like '%${queryParams.filter.toProfileId}%') AND `
    : ``;
  searchText += queryParams.filter.status ? `status=${queryParams.filter.status} AND` : ``;
  return searchText;
};
// Read  => filtered/sorted result
export async function getReportList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/MemberReport`, params);
  return response.data;
}

// GET => get the report from the server
export async function getReportById(reportId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/MemberReport/${reportId}`);
  return response.data;
}

// UPDATE => delete the report from the server
export async function updateReport(value) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/MemberReport`, value);
  return response.data;
}

// DELETE => delete the report from the server
export async function deleteReport(reportId) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/MemberReport?Id=${reportId}`
  );
  return response.data;
}
