import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getStatesList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    // searchText: "",
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/State/Get`, params);
  return response.data;
}

export async function getStateById(stateId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/State/${stateId}`);
  return response.data;
}

export async function getCountryList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Country`);
  return response.data;
}

// Create =>  POST: add a new state to the server
export async function createState(state) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/State`, state);
  return response.data;
}

// UPDATE => PUT: update the state on the server
export async function updateState(state) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/State`, state);
  return response.data;
}

// DELETE => delete the state from the server
export async function deleteState(stateId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/State?Id=${stateId}`);
  return response.data;
}
