import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_patidarsagai/layout';
import { DashboardPage } from './pages/DashboardPage';

const UserProfilePage = lazy(() => import('./modules/UserProfile/pages/UserProfilePage'));
const Member = lazy(() => import('./modules/Members/pages/MembersPage'));
const Master = lazy(() => import('./modules/Masters/pages/MastersPage'));
const Administrator = lazy(() => import('./modules/Administrator/pages/AdministratorPage'));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/member" component={Member} />
        <Route path="/master" component={Master} />
        <Route path="/administrator" component={Administrator} />
        <Route path="/user-profile" component={UserProfilePage} />
        <Redirect to="error/error-v2" />
      </Switch>
    </Suspense>
  );
}
