import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getCityList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/City/Get`, params);
  return response.data;
}

export async function getStateByCountryId(countryId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/State/Name?CountryId=${countryId}`
  );
  return response.data;
}

export async function getCountries() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Country`);
  return response.data;
}

export async function getCityById(cityId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/City/${cityId}`);
  return response.data;
}

// Create =>  POST: add a new city to the server
export async function createCity(city) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/City`, city);
  return response.data;
}

// UPDATE => PUT: update the city on the server
export async function updateCity(city) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/City`, city);
  return response.data;
}

// DELETE => delete the city from the server
export async function deleteCity(cityId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/City?Id=${cityId}`);
  return response.data;
}
