import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  searchText += queryParams.filter.type ? `Type=${queryParams.filter.type} AND` : ``;
  return searchText;
};

export async function findReasonList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Reason/Get`, params);
  return response.data;
}

export async function getReasonById(reasonId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Reason/${reasonId}`);
  return response.data;
}

// Create =>  POST: add a new request to the server
export async function createReason(reason) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Reason`, reason);
  return response.data;
}

// UPDATE => PUT: update the request on the server
export async function updateReason(reason) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Reason`, reason);
  return response.data;
}

// DELETE => delete the request from the server
export async function deleteReason(reasonId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/Reason?Id=${reasonId}`);
  return response.data;
}

export async function getReasonList(type) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Reason/Name?type=${type}`);
  return response.data;
}
