import axios from 'axios';

// Read  => filtered/sorted result
export async function getRequestList(queryParams) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Request`);
  return response.data;
}

export async function getRequestById(requestId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Request/${requestId}`);
  return response.data;
}

// Create =>  POST: add a new request to the server
export async function createRequest(request) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Request`, request);
  return response.data;
}

// UPDATE => PUT: update the request on the server
export async function updateRequest(request) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Request`, request);
  return response.data;
}

// DELETE => delete the request from the server
export async function deleteRequest(requestId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/Request?Id=${requestId}`);
  return response.data;
}
