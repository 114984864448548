import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function postNotificationTypeList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/NotificationType/Get`,
    params
  );
  return response.data;
}

export async function getNotificationTypeById(id) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/NotificationType/${id}`);
  return response.data;
}

// UPDATE => PUT: update the city on the server
export async function updateNotificationType(values) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/NotificationType`, values);
  return response.data;
}
