import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.email ? `(Email like '%${queryParams.filter.email}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getSubscribesList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Subscribe/Get`, params);
  return response.data;
}

// DELETE => delete the subscribes from the server
export async function deleteSubscribes(subscribesId) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/Subscribe?Id=${subscribesId}`
  );
  return response.data;
}
