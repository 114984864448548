import axios from 'axios';

export async function getNotificationTypeList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/NotificationType/Name`);
  return response.data;
}

export async function saveNotificationSetting(data) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/NotificationSetting`, data);
  return response.data;
}

export async function getNotificationSettingByUserId(userId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/NotificationSetting/${userId}`
  );
  return response.data;
}
