import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getCommunitiesList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    // searchText: "",
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Community/Get`, params);
  return response.data;
}

export async function getCommunityById(communityId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Community/${communityId}`);
  return response.data;
}

// Create =>  POST: add a new community to the server
export async function createCommunity(community) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Community`, community);
  return response.data;
}

// UPDATE => PUT: update the community on the server
export async function updateCommunity(community) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Community`, community);
  return response.data;
}

// DELETE => delete the community from the server
export async function deleteCommunity(communityId) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/Community?Id=${communityId}`
  );
  return response.data;
}
