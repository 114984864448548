import Swal from "sweetalert2/dist/sweetalert2.js";

export default function setupAxios(axios, store) {
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { accessToken },
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      const lang = localStorage.getItem("i18nextLng");
      // config.headers["Accept-Language"] = lang;

      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let errorMsg = [];
      const originalRequest = error.config;

      if (error && error.response && error.response.status && originalRequest && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          const {
            auth: { refreshToken, user },
          } = store.getState();
          axios
            .post(`${process.env.REACT_APP_API_URL}/Account/Refresh`, {
              refreshToken: refreshToken,
            })
            .then(({ data }) => {
              store.dispatch({
                type: "[Login] Action",
                payload: {
                  accessToken: data.accessToken,
                  refreshToken: data.refreshToken,
                  user: user,
                },
              });
              axios.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
              originalRequest.headers["Authorization"] = "Bearer " + data.accessToken;
              processQueue(null, data.accessToken);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              store.dispatch({ type: "[Logout] Action" });
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      } else if (error.response && error.response.data) {
        if (error.response.data.errors) {
          for (const key in error.response.data.errors) {
            errorMsg.push(` ${error.response.data.errors[key]}`);
          }
        } else {
          errorMsg.push(error.response.data);
        }
        return Promise.reject(errorMsg);
      } else {
        //handle other errors that not inside error.response.data
        if (error && error === "Network Error") {
          //handle the network error
          errorMsg.push("The network connection failed, Please retry and still persist error contact administrator.");
        } else {
          //handle other errors
          errorMsg.push("Something went wrong, Please retry and still persist error contact administrator.");
        }
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: "Oops...",
          text: errorMsg,
        });
        return Promise.reject(errorMsg);
      }
    }
  );
}
