import axios from 'axios';
const searchCondition = (queryParams) => {
  let searchText = '';

  searchText += queryParams.filter.profileId
    ? `(ProfileId like '%${queryParams.filter.profileId}%' OR `
    : ``;
  searchText += queryParams.filter.phoneNumber
    ? `PhoneNumber like '%${queryParams.filter.phoneNumber}%' OR `
    : ``;
  searchText += queryParams.filter.fullName
    ? `FullName like '%${queryParams.filter.fullName}%') AND `
    : ``;
  searchText += queryParams.filter.status ? `Status=${queryParams.filter.status} AND ` : ``;
  searchText += queryParams.filter.gender ? `Gender=${queryParams.filter.gender} AND ` : ``;
  return searchText;
};
export async function getMemberPremiumList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/MemberPremium/Get`, params);
  return response.data;
}

export async function getMemberPremiumById(memberPremiumId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/MemberPremium/${memberPremiumId}`
  );
  return response.data;
}

// export async function createMemberPremium(memberPremium) {
//   const response = await axios.post(
//     `${process.env.REACT_APP_API_URL}/MemberPremium`,
//     memberPremium
//   );
//   return response.data;
// }

// export async function updateMemberPremium(memberPremium) {
//   const response = await axios.post(
//     `${process.env.REACT_APP_API_URL}/MemberPremium`,
//     memberPremium
//   );
//   return response.data;
// }

export async function addEditMemberPremium(memberPremium) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/MemberPremium`,
    memberPremium
  );
  return response.data;
}

// DELETE => delete the request from the server
export async function deleteMemberPremium(memberPremiumId) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/MemberPremium?Id=${memberPremiumId}`
  );
  return response.data;
}

export async function getMemberList(searchText) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/MemberPremium/MemberList?searchText=${searchText}`
  );
  return response.data;
}
