import axios from 'axios';
import moment from 'moment';

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export async function getMemberConnectList(queryParams) {
  const sortText = [];

  if (typeof queryParams.sortField === 'object') {
    for (let i = 0; i < queryParams.sortField.length; i++) {
      sortText.push(`${queryParams.sortField[i]} ${queryParams.sortOrder[i]}`);
    }
  } else if (queryParams.sortField) {
    sortText.push(`${queryParams.sortField} ${queryParams.sortOrder}`);
  }
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: sortText.join(','),
    memberId: queryParams.memberId,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/MemberConnect`, params);
  return response.data;
}

const searchCondition = (queryParams) => {
  let searchText = queryParams.filter.FirstName
    ? `(FirstName like '%${queryParams.filter.FirstName}%' OR `
    : ``;
  searchText += queryParams.filter.LastName
    ? `LastName like '%${queryParams.filter.LastName}%' OR `
    : ``;
  searchText += queryParams.filter.ProfileId
    ? `ProfileId='${queryParams.filter.ProfileId}' ) `
    : ``;
  switch (queryParams.filter.status) {
    case 'Sent':
      searchText += `Id IN (SELECT ToMemberId FROM memberconnect WHERE FromStatus=1 AND ToStatus=0 AND FromMemberId=${queryParams.memberId}) AND `;
      break;
    case 'Received':
      searchText += `Id IN (SELECT ToMemberId FROM memberconnect WHERE FromStatus=0 AND ToStatus=1 AND FromMemberId=${queryParams.memberId}) AND `;
      break;
    case 'Connected':
      searchText += `Id IN (SELECT ToMemberId FROM memberconnect WHERE FromStatus=1 AND ToStatus=1 AND FromMemberId=${queryParams.memberId}) AND `;
      break;
    case 'Declined':
      searchText += `Id IN (SELECT ToMemberId FROM memberconnect WHERE FromStatus=2 AND ToStatus=0 AND FromMemberId=${queryParams.memberId}) AND `;
      break;
    case 'Blocked':
      searchText += `Id IN (SELECT ToMemberId FROM memberconnect WHERE FromStatus=3 AND ToStatus=0 AND FromMemberId=${queryParams.memberId}) AND `;
      break;
    default:
      searchText += `Id IN (SELECT ToMemberId FROM memberconnect WHERE (FromStatus!=0 OR ToStatus!=0) AND FromMemberId=${queryParams.memberId}) AND`;
      break;
  }
  switch (queryParams.filter.date) {
    case 'today':
      searchText += `date(createdOn)='${moment().format('YYYY-MM-DD')}' AND `;
      break;
    case 'yesterday':
      searchText += `DATE(createdOn) = SUBDATE('${moment().format(
        'YYYY-MM-DD'
      )}', INTERVAL 1 DAY) AND`;
      break;
    case 'week':
      searchText += `YEARWEEK(CreatedOn, 1) = YEARWEEK('${moment().format('YYYY-MM-DD')}', 1) AND `;
      break;
    case 'month':
      searchText += `MONTH(createdOn) = MONTH('${moment().format('YYYY-MM-DD')}') AND `;
      break;
    case 'customs':
      searchText += `CreatedOn BETWEEN '${moment(queryParams.filter.startDate).format(
        'YYYY-MM-DD'
      )}' AND '${moment(queryParams.filter.endDate).format('YYYY-MM-DD')}' AND`;
      break;
    case 'year':
      searchText += `YEAR(createdOn) = YEAR('${moment().format('YYYY-MM-DD')}') AND `;
      break;

    default:
      searchText += '';
      break;
  }

  return searchText;
};
