/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Members</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        {/* Member Pages */}
        {/*begin::1 Level*/}
        {/* <li className={`menu-item menu-item-submenu ${getMenuItemActive("/error", true)}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/member">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">Member</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Member</span>
                </span>
              </li> */}

        {/*begin::2 Level*/}
        {/* <li className={`menu-item ${getMenuItemActive("/member/member-list")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/member/member-list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Member List</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}

        {/*begin::2 Level*/}
        {/* <li className={`menu-item ${getMenuItemActive("/member/member-request")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/member/member-request">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Member Request</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}

        {/*begin::2 Level*/}
        {/* <li className={`menu-item ${getMenuItemActive("/member/images-request")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/member/images-request">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Member Photo</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}

        {/*begin::2 Level*/}
        {/* <li className={`menu-item ${getMenuItemActive("/member/report", false)}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/member/report">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Member Report</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}
        {/* </ul>
          </div>
        </li> */}

        <li
          className={`menu-item ${getMenuItemActive('/member/member-list', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/member/member-list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
            </span>
            <span className="menu-text">Member List</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/member/member-request', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/member/member-request">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Pencil.svg')} />
            </span>
            <span className="menu-text">Member Request</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/member/images-request', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/member/images-request">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Picture.svg')} />
            </span>
            <span className="menu-text">Member Photo</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/member/report', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/member/report">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')} />
            </span>
            <span className="menu-text">Member Report</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/member/member-premium', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/member/member-premium">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Selected-file.svg')} />
            </span>
            <span className="menu-text">Member Premium</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/* end:: section */}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Administrator</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* Member Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/error', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/master">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/Layout-left-panel-1.svg')} />
            </span>
            <span className="menu-text">Masters</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Masters</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive('/master/city')}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/master/city">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">City</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/master/community')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/community">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Community</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/master/education')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/education">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Education</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/master/request')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/request">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Request</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/master/state')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/state">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">State</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/master/village')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/village">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Village</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/master/topic')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/topic">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Topic</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/master/hobbies')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/hobbies">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Hobbies</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/master/reason')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/reason">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Reason</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                className={`menu-item ${getMenuItemActive('/master/notificationType')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/master/notificationType">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Notification Type</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/administrator/inquiries', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/administrator/inquiries">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Chat-check.svg')} />
            </span>
            <span className="menu-text">Inquiries</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/administrator/subscribes', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/administrator/subscribes">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Mail-box.svg')} />
            </span>
            <span className="menu-text">Subscribes</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/administrator/user-list', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/administrator/user-list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/administrator/login-history', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/administrator/login-history">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Media/Repeat.svg')} />
            </span>
            <span className="menu-text">Login History</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/administrator/ticket', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/administrator/ticket">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Ticket.svg')} />
            </span>
            <span className="menu-text">Ticket</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        <li
          className={`menu-item ${getMenuItemActive('/administrator/popup-setting', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/administrator/popup-setting">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/Layout-3d.svg')} />
            </span>
            <span className="menu-text">Popup Setting</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/administrator/push-notification', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/administrator/push-notification">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Chat2.svg')} />
            </span>
            <span className="menu-text">Push Notification</span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
