import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getEducationList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    // searchText: "",
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Education/Get`, params);
  return response.data;
}

export async function getEducationById(educationId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Education/${educationId}`);
  return response.data;
}

// Create =>  POST: add a new education to the server
export async function createEducation(education) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Education`, education);
  return response.data;
}

// UPDATE => PUT: update the education on the server
export async function updateEducation(education) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Education`, education);
  return response.data;
}

// DELETE => delete the education from the server
export async function deleteEducation(educationId) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/Education?Id=${educationId}`
  );
  return response.data;
}
