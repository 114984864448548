import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.title ? `(Title like '%${queryParams.filter.title}%' OR ` : ``;
  searchText += queryParams.filter.body ? `Body like '%${queryParams.filter.body}%') AND` : ``;
  return searchText;
};
export async function getNotificationList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/PushNotification/Get`,
    params
  );
  return response.data;
}

// Create =>  POST: add a new request to the server
export async function sendNotification(notification) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/PushNotification`,
    notification
  );
  return response.data;
}

// DELETE => delete the request from the server
export async function deleteNotification(notificationId) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/PushNotification?Id=${notificationId}`
  );
  return response.data;
}
