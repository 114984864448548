import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.title ? `(Title like '%${queryParams.filter.title}%' OR ` : ``;
  searchText += queryParams.filter.body ? `Body like '%${queryParams.filter.body}%' OR ` : ``;
  searchText += queryParams.filter.buttonText
    ? `ButtonText like '%${queryParams.filter.buttonText}%' OR `
    : ``;
  searchText += queryParams.filter.buttonAction
    ? `ButtonAction like '%${queryParams.filter.buttonAction}%') AND`
    : ``;
  return searchText;
};
export async function getPopupList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,

    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/PopupSetting/Get`, params);
  return response.data;
}

export async function getPopupById(popupId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/PopupSetting/${popupId}`);
  return response.data;
}

// Create =>  POST: add a new request to the server
export async function createPopup(popup) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/PopupSetting`, popup);
  return response.data;
}

// UPDATE => PUT: update the request on the server
export async function updatePopup(popup) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/PopupSetting`, popup);
  return response.data;
}

// DELETE => delete the request from the server
export async function deletePopup(popupId) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/PopupSetting?Id=${popupId}`
  );
  return response.data;
}
