import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getHobbiesList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Hobbies/Get`, params);
  return response.data;
}

export async function getHobbiesById(hobbiesId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Hobbies/${hobbiesId}`);
  return response.data;
}

// Create =>  POST: add a new Hobbies to the server
export async function createHobbies(hobbies) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Hobbies`, hobbies);
  return response.data;
}

// UPDATE => PUT: update the Hobbies on the server
export async function updateHobbies(hobbies) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Hobbies`, hobbies);
  return response.data;
}

// DELETE => delete the Hobbies from the server
export async function deleteHobbies(hobbiesId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/Hobbies?Id=${hobbiesId}`);
  return response.data;
}
