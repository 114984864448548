import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
};

const initialAuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: 'ps-admin', whitelist: ['user', 'accessToken', 'refreshToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { accessToken, refreshToken, user } = action.payload;
        return { ...state, accessToken, user: user, refreshToken };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken, refreshToken, user) => ({
    type: actionTypes.Login,
    payload: { accessToken, refreshToken, user },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {}
