import axios from 'axios';
import moment from 'moment';

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result

const searchCondition = (queryParams) => {
  let searchText = queryParams.filter.isDeleted === 'Deleted' ? 'IsDeleted=1 AND ' : '';
  searchText += queryParams.filter.isDeleted === 'UnDeleted' ? 'IsDeleted=0 AND ' : '';
  searchText += queryParams.filter.anyDisability
    ? `AnyDisability=${queryParams.filter.anyDisability} AND `
    : '';
  searchText += queryParams.filter.isNRI ? `IsNRI=${queryParams.filter.isNRI} AND ` : '';
  searchText += queryParams.filter.educationId
    ? `EducationId=${queryParams.filter.educationId} AND `
    : '';
  searchText += queryParams.filter.occupationId
    ? `OccupationId=${queryParams.filter.occupationId} AND `
    : '';
  searchText += queryParams.filter.communityId
    ? `communityId=${queryParams.filter.communityId} AND `
    : ``;
  searchText += queryParams.filter.status ? `ApprovalStatus=${queryParams.filter.status} AND ` : ``;
  searchText += queryParams.filter.gender ? `Gender=${queryParams.filter.gender} AND ` : ``;
  searchText +=
    'BirthDate BETWEEN "' +
    moment().subtract(queryParams.filter.ageTo, 'year').format() +
    '" AND "' +
    moment().subtract(queryParams.filter.ageFrom, 'year').format() +
    '" AND ';
  searchText += queryParams.filter.FirstName
    ? `(FirstName like '%${queryParams.filter.FirstName}%' OR `
    : ``;
  searchText += queryParams.filter.LastName
    ? `LastName like '%${queryParams.filter.LastName}%' OR `
    : ``;
  searchText += queryParams.filter.NativePlace
    ? `NativePlace='${queryParams.filter.NativePlace}' OR `
    : ``;
  searchText += queryParams.filter.ProfileId
    ? `ProfileId='${queryParams.filter.ProfileId}' OR `
    : ``;
  searchText += queryParams.filter.ParentPhone1
    ? `ParentPhone1='${queryParams.filter.ParentPhone1}' OR `
    : ``;
  searchText += queryParams.filter.ParentPhone1
    ? `UserId IN(SELECT Id FROM aspnetusers WHERE PhoneNumber='${queryParams.filter.ParentPhone1}' OR Email='${queryParams.filter.ParentPhone1}')) AND `
    : ``;
  switch (queryParams.filter.date) {
    case 'today':
      searchText += `date(createdOn)='${moment().format('YYYY-MM-DD')}' AND `;
      break;
    case 'yesterday':
      searchText += `DATE(createdOn) = SUBDATE('${moment().format(
        'YYYY-MM-DD'
      )}', INTERVAL 1 DAY) AND`;
      break;
    case 'week':
      searchText += `YEARWEEK(CreatedOn, 1) = YEARWEEK('${moment().format('YYYY-MM-DD')}', 1) AND `;
      break;
    case 'month':
      searchText += `MONTH(createdOn) = MONTH('${moment().format('YYYY-MM-DD')}') AND `;
      break;
    case 'customs':
      searchText += `CreatedOn BETWEEN '${moment(queryParams.filter.startDate).format(
        'YYYY-MM-DD'
      )}' AND '${moment(queryParams.filter.endDate).format('YYYY-MM-DD')}' AND`;
      break;
    case 'year':
      searchText += `YEAR(createdOn) = YEAR('${moment().format('YYYY-MM-DD')}') AND `;
      break;

    default:
      searchText += '';
      break;
  }

  return searchText;
};

export async function getMemberListData(queryParams) {
  const sortText = ['approvalStatus=1 DESC'];

  if (typeof queryParams.sortField === 'object') {
    for (let i = 0; i < queryParams.sortField.length; i++) {
      sortText.push(`${queryParams.sortField[i]} ${queryParams.sortOrder[i]}`);
    }
  } else if (queryParams.sortField) {
    sortText.push(`${queryParams.sortField} ${queryParams.sortOrder}`);
  }
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: sortText.join(','),
    // searchText: "",
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Member`, params);
  return response.data;
}

//* READ
export async function getCommunityList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Community/Name`);
  return response.data;
}

export async function getHeightList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Height/Name`);
  return response.data;
}

export async function getOccupationList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Occupation/Name`);
  return response.data;
}

export async function getCountryListData() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Country/Name`);
  return response.data;
}

export async function getStateList(Id) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/State/Name?CountryId=${Id}`);
  return response.data;
}

export async function getCityListData(Id) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/City/Name?StateId=${Id}`);
  return response.data;
}

export async function getVillageListData() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Village/Name`);
  return response.data;
}

export async function getEducationListData() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Education/Name`);
  return response.data;
}

export async function getHobbiesListData() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Hobbies/Name?v=3.1`);
  return response.data;
}

export async function getAnnualIncomeList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/AnnualIncome/Name`);
  return response.data;
}

export async function getMemberImagesData(values) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/MemberImage/${values}`);
  return response.data;
}

export async function removeMemberImage(id) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/MemberImage?id=${id}`);
  return response.data;
}

export async function setDefaultMemberImage(values) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/MemberImage/Default`, values);
  return response.data;
}

//* Get Signal Member
export async function getMemberById(memberId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Member/${memberId}`);
  return response.data;
}

export async function postAccountDetails(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Member/AccountDetails`,
    values
  );
  return response.data;
}

export async function postPersonalDetails(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Member/PersonalDetails`,
    values
  );
  return response.data;
}

export async function postLifestyleDetails(values) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Member/FamilyDetails`, values);
  return response.data;
}

export async function postProfessionalValueDetails(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Member/ProfessionalDetails`,
    values
  );
  return response.data;
}

export async function postUploadPhotoDetails(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/MemberImage/UploadPhoto`,
    values
  );
  return response.data;
}

export async function putUpdateStatus(values) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Member/UpdateStatus`, values);
  return response.data;
}

export async function putDeleteAccount(userId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Member/DeleteAccount?userId=${userId}`
  );
  return response.data;
}

export async function putUnDeleteAccount(userId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Member/UndeleteAccount?userId=${userId}`
  );
  return response.data;
}

export async function putActiveAccount(userId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Member/ActiveAccount?userId=${userId}`
  );
  return response.data;
}

export async function putDeActiveAccount(userId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Member/DeActiveAccount?userId=${userId}`
  );
  return response.data;
}

export async function putBlockAccount(userId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Member/BlockAccount?userId=${userId}`
  );
  return response.data;
}

export async function putUnBlockAccount(userId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Member/UnBlockAccount?userId=${userId}`
  );
  return response.data;
}

export async function postLogoutAccount(userId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Member/Logout?userId=${userId}`
  );
  return response.data;
}

export async function updateMemberImageApprove(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberImage/Approved?Id=${values}`
  );
  return response.data;
}

export async function updateMemberImageReject(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberImage/Rejected?Id=${values}`,
    values
  );
  return response.data;
}

export async function getAdminNotes(memberId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/MemberAdminNotes/ByMemberId/${memberId}`
  );
  return response.data;
}

export async function addAdminNotes(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/MemberAdminNotes`, values);
  return response.data;
}

export async function deleteAdminNotes(id) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/MemberAdminNotes?Id=${id}`);
  return response.data;
}

export async function getNotification(UserId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Notifications/${UserId}`);
  return response.data;
}

export async function getHistory(memberId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/MemberHistory/${memberId}`);
  return response.data;
}

export async function getLoginHistory(UserId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/LoginHistory/${UserId}`);
  return response.data;
}

export async function getMemberShortList(memberId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/MemberShortList/${memberId}`);
  return response.data;
}

// Post new member
export async function postNewMember(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Member/NewMember`, values);
  return response.data;
}
// Member connect count
export async function getMemberConnectCount(memberId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/MemberConnect/ConnectCount?memberId=${memberId}`
  );
  return response.data;
}

// Activity, Notification, LoginHistory count
export async function getNALCount(memberId, userId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/MemberHistory/GetNALCount?memberId=${memberId}&userId=${userId}`
  );
  return response.data;
}

// Send notification
export async function postNotification(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Member/SendNotification`,
    values
  );
  return response.data;
}

export async function addEditSettingPreference(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/SettingPreference`, values);
  return response.data;
}

export async function getSettingPreferenceById(memberId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/SettingPreference/${memberId}`
  );
  return response.data;
}

// Download bio
export async function downloadBioData(values) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/Member/GetPVBioData?userId=` + values,
    ('download-pdf', { responseType: 'arraybuffer' })
  );
  return response.data;
}

export async function getPartnerPreferences(id) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/PartnerPreference/${id}`);
  return response.data;
}

export async function updatePartnerPreference(partnerPreference) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/PartnerPreference`,
    partnerPreference
  );
  return response.data;
}
