import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import * as auth from '../_redux/authRedux';
import { login } from '../_redux/authCrud';
import Recaptcha from 'react-google-invisible-recaptcha';

const initialValues = {
  userName: '',
  password: '',
};

function Login(props) {
  let reCaptchaObj = React.useRef();
  const LoginSchema = Yup.object().shape({
    userName: Yup.string().max(50, 'Maximum 50 characters').required('Username is required'),
    password: Yup.string()
      .min(6, 'Minimum 6 characters')
      .max(256, 'Maximum 256 characters')
      .required('Password is required'),
    captchaResponse: Yup.string(),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return 'is-valid';
    }
    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus();
      login(values.userName, values.password, values.captchaResponse)
        .then(({ data: { accessToken, refreshToken }, data }) => {
          props.login(accessToken, refreshToken, data.user);
        })
        .catch((error) => setStatus(error))
        .finally(() => setSubmitting(false));
    },
  });

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        formik.setSubmitting(true);
        reCaptchaObj.current.reset();
        reCaptchaObj.current.execute();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Login Account</h3>
        <p className="text-muted font-weight-bold">Enter your username and password</p>
      </div>
      {/* end::Head */}
      {formik.status && (
        <div
          className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
          style={{ display: 'block' }}
        >
          <div
            className="alert-text font-weight-bold"
            dangerouslySetInnerHTML={{ __html: formik.status }}
          ></div>
        </div>
      )}
      {/*begin::Form*/}
      <form className="form fv-plugins-bootstrap fv-plugins-framework">
        <div className="form-group fv-plugins-icon-container">
          <input
            autoFocus
            placeholder="Username"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'userName'
            )}`}
            name="userName"
            {...formik.getFieldProps('userName')}
          />
          {formik.touched.userName && formik.errors.userName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.userName}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Forgot Password
          </Link>
          <button
            id="kt_login_signin_submit"
            type="button"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            onClick={() => {
              formik.setSubmitting(true);
              reCaptchaObj.current.reset();
              reCaptchaObj.current.execute();
            }}
          >
            <span>Sign In</span>
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        <Recaptcha
          ref={reCaptchaObj}
          sitekey="6LcpVDsaAAAAANOyJmqw0Z3jfOCP9vNtcmyzD6N5"
          onResolved={() => {
            const captchaResp = reCaptchaObj.current.getResponse();
            formik.setFieldValue('captchaResponse', captchaResp);
            formik.handleSubmit();
          }}
        />
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default connect(null, auth.actions)(Login);
