import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/Account/Login`;
export const LOGOUT_URL = `${process.env.REACT_APP_API_URL}/Account/Logout`;
export const REFRESH_URL = `${process.env.REACT_APP_API_URL}/Account/Refresh`;
export const FORGOT_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/Account/ForgetPassword`;
export const VERIFY_FORGOT_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/Account/VerifyForgetPassword`;

export function login(username, password, captchaResponse) {
  return axios.post(LOGIN_URL, { username, password, captchaResponse });
}

export function logout(data) {
  return axios.post(LOGOUT_URL, data);
}

export function refresh(data) {
  return axios.post(REFRESH_URL, data);
}

export function forgotPassword(email) {
  return axios.post(FORGOT_PASSWORD_URL, { email });
}

export function verifyForgotPassword(data) {
  return axios.post(VERIFY_FORGOT_PASSWORD_URL, data);
}
