import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getTopicList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Topic/Get`, params);
  return response.data;
}
export async function getTopicById(topicId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Topic/${topicId}`);
  return response.data;
}

// Create =>  POST: add a new topic to the server
export async function createTopic(value) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Topic`, value);
  return response.data;
}

// UPDATE => PUT: update the topic on the server
export async function updateTopic(value) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Topic`, value);
  return response.data;
}

// DELETE => delete the topic from the server
export async function deleteTopic(topicId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/Topic?Id=${topicId}`);
  return response.data;
}
