import axios from 'axios';

export const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/Account/ChangePassword`;
export const POST_PROFILE_URL = `${process.env.REACT_APP_API_URL}/Account`;
export const GET_PROFILE_URL = `${process.env.REACT_APP_API_URL}/Account`;

export async function changePassword(data) {
  const response = await axios.post(CHANGE_PASSWORD_URL, data);
  return response.data;
}

export async function getProfileDetails() {
  const response = await axios.get(GET_PROFILE_URL);
  return response.data;
}

export async function postProfile(data) {
  const response = await axios.post(POST_PROFILE_URL, data);
  return response.data;
}

export async function getDashboardDetails() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Dashboard`);
  return response.data;
}
