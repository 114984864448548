import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') OR ` : ``;
  searchText += queryParams.filter.email ? `(Email like '%${queryParams.filter.email}%') OR ` : ``;
  searchText += queryParams.filter.phone
    ? `(PhoneNumber like '%${queryParams.filter.phone}%') AND `
    : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getInquiryList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    // searchText: "",
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Inquiry/Get`, params);
  return response.data;
}

// DELETE => delete the inquiries from the server
export async function deleteInquiries(inquiriesId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/Inquiry?Id=${inquiriesId}`);
  return response.data;
}
