import axios from 'axios';
const searchCondition = (queryParams) => {
  let searchText =
    queryParams.filter.profileIsDeletedStatus === 'Deleted' ? 'ProfileIsDeletedStatus=1 AND ' : '';
  searchText +=
    queryParams.filter.profileIsDeletedStatus === 'UnDeleted'
      ? 'ProfileIsDeletedStatus=0 AND '
      : '';
  searchText += queryParams.filter.status ? `ApprovalStatus=${queryParams.filter.status} AND ` : ``;
  searchText += queryParams.filter.profileStatus
    ? `ProfileStatus=${queryParams.filter.profileStatus} AND `
    : ``;
  searchText += queryParams.filter.fullName
    ? `(FullName like '%${queryParams.filter.fullName}%' OR `
    : ``;
  searchText += queryParams.filter.profileId
    ? `ProfileId='${queryParams.filter.profileId}') AND `
    : ``;
  return searchText;
};

// Get API
export async function getImagesRequestList(queryParams) {
  const sortText = ['profileStatus=3 DESC'];
  if (typeof queryParams.sortField === 'object') {
    for (let i = 0; i < queryParams.sortField.length; i++) {
      sortText.push(`${queryParams.sortField[i]} ${queryParams.sortOrder[i]}`);
    }
  } else if (queryParams.sortField) {
    sortText.push(`${queryParams.sortField} ${queryParams.sortOrder}`);
  }
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: sortText.join(','),
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/MemberImage`, params);
  return response.data;
}

export async function approveImagesRequest(imagesRequestId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberImage/Approved`,
    imagesRequestId
  );
  return response.data;
}

export async function approveAllImagesRequest(imagesRequestId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberImage/ApprovedAll`,
    imagesRequestId
  );
  return response.data;
}
export async function rejectImagesRequest(imagesRequestObj) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberImage/Rejected`,
    imagesRequestObj
  );
  return response.data;
}

export async function rejectAllImagesRequest(allImagesRequestObj) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberImage/RejectedAll`,
    allImagesRequestObj
  );
  return response.data;
}

export async function updateStatusForImagesRequests(ids, status, reasonToReject, notifyUser) {
  if (status === '1') {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/MemberImage/Approved`, ids);
    return response.data;
  } else {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/MemberImage/Rejected`, {
      ids,
      reasonToReject,
      notifyUser,
    });
    return response.data;
  }
}

// Delete API
export async function deleteImagesRequest(imagesRequestId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/MemberImage`, {
    data: imagesRequestId,
  });
  return response.data;
}
