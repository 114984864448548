import axios from 'axios';

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.status ? `ApprovalStatus=${queryParams.filter.status} AND ` : ``;
  searchText += queryParams.filter.fullName
    ? `(FullName like '%${queryParams.filter.fullName}%' OR `
    : ``;
  searchText += queryParams.filter.profileId
    ? `ProfileId='${queryParams.filter.profileId}') AND `
    : ``;
  return searchText;
};

// Get API
export async function getMemberRequestList(queryParams) {
  const sortText = [];
  if (typeof queryParams.sortField === 'object') {
    for (let i = 0; i < queryParams.sortField.length; i++) {
      sortText.push(`${queryParams.sortField[i]} ${queryParams.sortOrder[i]}`);
    }
  } else if (queryParams.sortField) {
    sortText.push(`${queryParams.sortField} ${queryParams.sortOrder}`);
  }
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: sortText.join(','),
    // searchText: "",
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/MemberRequest`, params);
  return response.data;
}

// Add API

// Update API
export async function approveMemberRequest(memberRequestId) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberRequest/Approved`,
    memberRequestId
  );
  return response.data;
}
export async function rejectMemberRequest(memberRequestObj) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberRequest/Rejected`,
    memberRequestObj
  );
  return response.data;
}

export async function updateStatusForMembersRequest(ids, status, reason) {
  if (status === '1') {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/MemberRequest/Approved`,
      ids
    );
    return response.data;
  } else {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/MemberRequest/Rejected`, {
      ids,
      reason,
    });
    return response.data;
  }
}

// Delete API
export async function deleteMemberRequest(memberRequestId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/MemberRequest`, {
    data: memberRequestId,
  });
  return response.data;
}
