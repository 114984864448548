import axios from 'axios';

const searchCondition = (queryParams) => {
  let searchText = '';
  searchText += queryParams.filter.name ? `(Name like '%${queryParams.filter.name}%') AND ` : ``;
  return searchText;
};

// Read  => filtered/sorted result
export async function getVillageList(queryParams) {
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: `${queryParams.sortField} ${queryParams.sortOrder}`,
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Village/Get`, params);
  return response.data;
}
export async function getVillageById(villageId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Village/${villageId}`);
  return response.data;
}

// Create =>  POST: add a new village to the server
export async function createVillage(village) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Village`, village);
  return response.data;
}

// UPDATE => PUT: update the village on the server
export async function updateVillage(village) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Village`, village);
  return response.data;
}

// DELETE => delete the village from the server
export async function deleteVillage(villageId) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/Village?Id=${villageId}`);
  return response.data;
}
