import axios from 'axios';
import moment from 'moment';

export async function getTicketList(queryParams) {
  const sortText = [];

  if (queryParams.sortField) {
    sortText.push(`${queryParams.sortField} ${queryParams.sortOrder}`);
  }

  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: sortText.join(','),

    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Ticket/Get`, params);
  return response.data;
}

const searchCondition = (queryParams) => {
  let searchText =
    queryParams.filter.status != null ? `t.Status=${queryParams.filter.status} AND ` : ``;

  searchText += queryParams.filter.ticketNumber
    ? `(t.TicketNumber like '%${queryParams.filter.ticketNumber}%' OR `
    : ``;
  searchText += queryParams.filter.name ? `t.Name like '%${queryParams.filter.name}%' OR ` : ``;
  searchText += queryParams.filter.email ? `t.Email like '%${queryParams.filter.email}%' OR ` : ``;
  searchText += queryParams.filter.mobile ? `t.Mobile='${queryParams.filter.mobile}' ) AND ` : ``;

  switch (queryParams.filter.date) {
    case 'today':
      searchText += `date(t.createdOn)='${moment().format('YYYY-MM-DD')}' AND `;
      break;
    case 'yesterday':
      searchText += `DATE(t.createdOn) = SUBDATE('${moment().format(
        'YYYY-MM-DD'
      )}', INTERVAL 1 DAY) AND`;
      break;
    case 'week':
      searchText += `YEARWEEK(t.CreatedOn, 1) = YEARWEEK('${moment().format(
        'YYYY-MM-DD'
      )}', 1) AND `;
      break;
    case 'month':
      searchText += `MONTH(t.createdOn) = MONTH('${moment().format('YYYY-MM-DD')}') AND `;
      break;
    case 'customs':
      searchText += `date(t.createdOn) BETWEEN '${moment(queryParams.filter.startDate).format(
        'YYYY-MM-DD'
      )}' AND '${moment(queryParams.filter.endDate).format('YYYY-MM-DD')}' AND`;
      break;
    case 'year':
      searchText += `YEAR(t.createdOn) = YEAR('${moment().format('YYYY-MM-DD')}') AND `;
      break;

    default:
      searchText += '';
      break;
  }

  return searchText;
};

//* Get
export async function getTicketById(ticketId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Ticket/${ticketId}`);
  return response.data;
}

//* Post
export async function sendMessage(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Ticket/TicketReply`, values);
  return response.data;
}

//* Get
export async function closedTicket(ticketId, isNotifyUser) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/Ticket/ClosedTicket?ticketId=${ticketId}&isNotifyUser=${isNotifyUser}`
  );
  return response.data;
}
