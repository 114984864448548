import axios from 'axios';
import moment from 'moment';

export async function getUserList(queryParams) {
  const sortText = [];

  if (queryParams.sortField) {
    sortText.push(`${queryParams.sortField} ${queryParams.sortOrder}`);
  }
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: sortText.join(','),

    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/User/Get`, params);
  return response.data;
}

const searchCondition = (queryParams) => {
  let searchText = queryParams.filter.isDeleted ? 'a.IsDeleted=1 AND ' : 'a.IsDeleted=0 AND ';
  searchText += queryParams.filter.FirstName
    ? `(a.FirstName like '%${queryParams.filter.FirstName}%' OR `
    : ``;
  searchText += queryParams.filter.LastName
    ? `a.LastName like '%${queryParams.filter.LastName}%' OR `
    : ``;
  searchText += queryParams.filter.UserName
    ? `a.UserName like '%${queryParams.filter.UserName}%' OR `
    : ``;
  searchText += queryParams.filter.Email ? `a.Email like '%${queryParams.filter.Email}%' OR ` : ``;
  searchText += queryParams.filter.PhoneNumber
    ? `a.PhoneNumber like '%${queryParams.filter.PhoneNumber}%' OR `
    : ``;
  searchText += queryParams.filter.ProfileId
    ? `m.ProfileId='${queryParams.filter.ProfileId}' ) AND `
    : ``;
  searchText += queryParams.filter.isNotMember ? `m.ProfileId is null AND ` : ``;
  switch (queryParams.filter.date) {
    case 'today':
      searchText += `date(a.createdOn)='${moment().format('YYYY-MM-DD')}' AND `;
      break;
    case 'yesterday':
      searchText += `DATE(a.createdOn) = SUBDATE('${moment().format(
        'YYYY-MM-DD'
      )}', INTERVAL 1 DAY) AND`;
      break;
    case 'week':
      searchText += `YEARWEEK(a.CreatedOn, 1) = YEARWEEK('${moment().format(
        'YYYY-MM-DD'
      )}', 1) AND `;
      break;
    case 'month':
      searchText += `MONTH(a.createdOn) = MONTH('${moment().format('YYYY-MM-DD')}') AND `;
      break;
    case 'customs':
      searchText += `date(a.createdOn) BETWEEN '${moment(queryParams.filter.startDate).format(
        'YYYY-MM-DD'
      )}' AND '${moment(queryParams.filter.endDate).format('YYYY-MM-DD')}' AND`;
      break;
    case 'year':
      searchText += `YEAR(a.createdOn) = YEAR('${moment().format('YYYY-MM-DD')}') AND `;
      break;

    default:
      searchText += '';
      break;
  }

  return searchText;
};

//* Get Signal User
export async function getUserById(userId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/User/${userId}`);
  return response.data;
}

export async function postUser(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/User`, values);
  return response.data;
}

export async function putUser(values) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/User`, values);
  return response.data;
}

export async function verifyEmailPhone(userId, type) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/User/ConfirmEmailOrPhone?userId=${userId}&confirmField=${type}`
  );
  return response.data;
}

export async function postChangePassword(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/User/ChangePassword`, values);
  return response.data;
}

export async function postLogoutAllAccount() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/User/LogoutAll`);
  return response.data;
}
