import axios from 'axios';
import moment from 'moment';

export async function getLoginHistoryList(queryParams) {
  const sortText = [];

  if (queryParams.sortField) {
    sortText.push(`${queryParams.sortField} ${queryParams.sortOrder}`);
  }
  const params = {
    pageIndex: queryParams.pageNumber,
    pageSize: queryParams.pageSize,
    sortText: sortText.join(','),
    searchText: searchCondition(queryParams),
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/LoginHistory/Get`, params);
  return response.data;
}

const searchCondition = (queryParams) => {
  let searchText = queryParams.filter.FirstName
    ? `(u.FirstName like '%${queryParams.filter.FirstName}%' OR `
    : ``;
  searchText += queryParams.filter.LastName
    ? `u.LastName like '%${queryParams.filter.LastName}%' OR `
    : ``;
  searchText += queryParams.filter.UserName
    ? `u.UserName like '%${queryParams.filter.UserName}%' OR `
    : ``;
  searchText += queryParams.filter.Email ? `u.Email like '%${queryParams.filter.Email}%' OR ` : ``;
  searchText += queryParams.filter.PhoneNumber
    ? `u.PhoneNumber like '%${queryParams.filter.PhoneNumber}%' OR `
    : ``;
  searchText += queryParams.filter.ProfileId
    ? `m.ProfileId='${queryParams.filter.ProfileId}' ) AND `
    : ``;

  switch (queryParams.filter.date) {
    case 'today':
      searchText += `date(lh.LoginTime)='${moment().format('YYYY-MM-DD')}' AND `;
      break;
    case 'yesterday':
      searchText += `DATE(lh.LoginTime) = SUBDATE('${moment().format(
        'YYYY-MM-DD'
      )}', INTERVAL 1 DAY) AND`;
      break;
    case 'week':
      searchText += `YEARWEEK(lh.LoginTime, 1) = YEARWEEK('${moment().format(
        'YYYY-MM-DD'
      )}', 1) AND `;
      break;
    case 'month':
      searchText += `MONTH(lh.LoginTime) = MONTH('${moment().format('YYYY-MM-DD')}') AND `;
      break;
    case 'customs':
      searchText += `date(lh.LoginTime) BETWEEN '${moment(queryParams.filter.startDate).format(
        'YYYY-MM-DD'
      )}' AND '${moment(queryParams.filter.endDate).format('YYYY-MM-DD')}' AND`;
      break;
    case 'year':
      searchText += `YEAR(lh.LoginTime) = YEAR('${moment().format('YYYY-MM-DD')}') AND `;
      break;

    default:
      searchText += '';
      break;
  }

  return searchText;
};
